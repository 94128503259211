import img1 from "../assets/preview images/preview-1.jpeg"
import img2 from "../assets/preview images/preview-2.jpeg"
import img3 from "../assets/preview images/preview-11.jpeg"
import img4 from "../assets/preview images/preview-8.jpg"
import img5 from "../assets/preview images/preview-10.jpg"
import img6 from "../assets/preview images/preview-6.jpeg"
import img7 from "../assets/preview images/preview-7.jpeg"
import img8 from "../assets/preview images/preview-9.jpeg"


export const Imgs = [
    {
        alt: "image 1",
        imgSrc:img1 ,
        title: "img 1",
        number: 1
    },
    {
        alt: "image 2",
        imgSrc:img2 ,
        title: "img 2",
        number: 2
    },
    {
        alt: "image 3",
        imgSrc:img3 ,
        title: "img 3",
        number: 3
    },
    {
        alt: "image 4",
        imgSrc:img4 ,
        title: "img 4",
        number: 4
    },
    {
        alt: "image 5",
        imgSrc:img5 ,
        title: "img 5",
        number: 5
    },
    {
        alt: "image 6",
        imgSrc:img6 ,
        title: "img 6",
        number: 6
    },
    {
        alt: "image 7",
        imgSrc:img7 ,
        title: "img 7",
        number: 7
    },
    {
        alt: "image 8",
        imgSrc:img8 ,
        title: "img 8",
        number: 8
    },
]