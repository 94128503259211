import './polyfills';

import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';

import ReactGa from 'react-ga4';

import { BrowserRouter } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';

ReactGa.initialize("G-QV645P4YWV")

ReactGa.send({
  hitType: 'pageview',
  page: window.location.pathname,
});

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <HelmetProvider>
        <App />
      </HelmetProvider>
    </BrowserRouter>
  </React.StrictMode>
);

