import { Helmet } from "react-helmet-async";
import Footer from "../../components/Footer/footer.component";

import PageHero from "../../components/Page Hero/page-hero.component";
import GallerySection from "../../sections/gallery-section/gallery-section.component";
import "./gallery-page.styles.scss";

import React from 'react'

const GalleryPage = () => {
  return (
    <>
    <Helmet>
      <title>Our Stainless Steel Solutions  | Vine Tech Engineering</title>
      <meta name="description" content="Our stainless steel fittings, installations, and engineering projects in the  food and wine industry." />
      <link rel="canonical" href="gallery"/>
    </Helmet>
      <PageHero img={3}>Our Gallery</PageHero>
      <GallerySection/>
      <Footer />
    </>
  )
}

export default GalleryPage
