import React from 'react'

import './home-page.styles.scss'
import HeroSection from '../../sections/hero-section/hero-section.component';
import InfoSection from '../../sections/info-section/info-section.component';
import Footer from '../../components/Footer/footer.component';
import GalleryPreviewSection from '../../sections/gallery-preview-section/gallery-preview-section.component';
// import FaqSection from '../../sections/faq-section/faq-section.component';
import ContactFormSection from '../../sections/contact-form-section/contact-form-section.component';
import { Helmet } from 'react-helmet-async';


const HomePage = () => {
  return (
    <>
    <Helmet>
      <title>VINE TECH ENGINEERING | Pioneering Stainless Steel Solutions for the Food and Wine Industry</title>
      <meta name="description" content="Delivering Exceptional Service and Custom Fittings Since 1997, Proudly Serving the Boland and Surrounding Areas with Comprehensive Engineering Services." />
      <link rel="canonical" href="/"/>
    </Helmet>
      <HeroSection/>
      <InfoSection/>
      <GalleryPreviewSection/>
      {/* <FaqSection /> */}
      <ContactFormSection/>
      <Footer/>
    </>
  );
}

export default HomePage;