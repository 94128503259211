import img1 from "../assets/page hero images/WhatsApp Image 2024-06-06 at 17.43.50 (4).jpeg"
import img2 from "../assets/page hero images/WhatsApp Image 2024-06-06 at 17.43.51 (4).jpeg"
import img3 from "../assets/page hero images/gallery head img.jpeg"




export const slides = [
    {
        alt: "image 1",
        src:img1 ,
    },
    {
        alt: "image 2",
        src:img2 ,
    },
    {
        alt: "image 3",
        src:img3 ,
    },
]
